import { ConnectWallet } from "@thirdweb-dev/react";
import NFTRendering from "./components/NFTRendering";
import "./styles/Home.css";

export default function Home() {
 

  return (
    <div className="container">
      <main className="main">
        <div style={{width:'400px'}}>
       </div>
        <div className="connect">
          <ConnectWallet />
        </div>
        <NFTRendering />
      </main>
    </div>
  );
}
