import {
  ChainId,
  ThirdwebNftMedia,
  useAddress,
  useContract,
  useContractWrite,
  useEdition,
  useNetwork,
  useNetworkMismatch,
  useNFTCollection,
  useOwnedNFTs,
  useSDK,
} from "@thirdweb-dev/react";
import { useEffect, useState } from "react";
import { configEnv } from "../configEnv";
import "../styles/Home.css";

const NFTRendering = () => {
  const sdk = useSDK();

  const address = useAddress();

  const nftNumber1 = useNFTCollection(configEnv?.NFT1Address);

  const nftNumber2 = useEdition(configEnv?.NFT2Address);

  const sneakerNft = useContract(configEnv?.SneakerNFTContractAddress);

  const { mutate: claimNFT } = useContractWrite(
    sneakerNft?.contract,
    "claimNFT"
  );

  const ownedNfts1 = useOwnedNFTs(nftNumber1,address);

  const ownedNfts2 = useOwnedNFTs(nftNumber2,address);

  const [selectedNfts1, setSelectedNfts1] = useState<number>(-1);
  const [selectedNfts2, setSelectedNfts2] = useState<number>(-1);
  const [equipLoading, setEquipLoading] = useState<boolean>(false);

  const [changeDetection,setChangeDetection] = useState(false);

  
  const [network, switchNetwork] = useNetwork(); // Switch to desired chain
  const isMismatched = useNetworkMismatch(); // Detect if user is connected to the wrong network

  useEffect(() => {
    // Check if the user is connected to the wrong network
    if (isMismatched && network && switchNetwork) {
      // Prompt their wallet to switch networks
      switchNetwork(ChainId.BinanceSmartChainTestnet); // the chain you want here
    }
  }, [isMismatched]);

  useEffect(()=>{
    console.log("change detected");
  },[changeDetection])

  const selectNft1 = (nftId: number) => {
    if (selectedNfts1 !== nftId) {
      setSelectedNfts1(nftId);
    } else {
      setSelectedNfts1(-1);
    }
  };

  const selectNft2 = (nftId: number) => {
    if (selectedNfts2 !== nftId) {
      setSelectedNfts2(nftId);
    } else {
      setSelectedNfts2(-1);
    }
  };

  const mintSneaker = async () => {
    try {
      setEquipLoading(true);
      const nft1Contract = await sdk?.getNFTCollection(configEnv?.NFT1Address);

      const isApprovedForNft1 = await nft1Contract?.call(
        "isApprovedForAll",
        address,
        configEnv?.SneakerNFTContractAddress
      );

      if (!isApprovedForNft1) {
        const approvalForNft1 = await nft1Contract?.call(
          "approve",
          configEnv?.SneakerNFTContractAddress,
          selectedNfts1
        );
        console.log(approvalForNft1);
      }
      
      const nft2Contract = await sdk?.getEdition(configEnv?.NFT2Address);
      
      const isApprovedForNft2 = await nft2Contract?.call(
        "isApprovedForAll",
        address,
        configEnv?.SneakerNFTContractAddress
      );

      debugger
      if (!isApprovedForNft2) {
        const approvalForNft2 = await nft2Contract?.call(
          "setApprovalForAll",
          configEnv?.SneakerNFTContractAddress,
          true
        );
        console.log(approvalForNft2);
      }

      const claimNftContract = await sdk?.getContract(configEnv?.SneakerNFTContractAddress,);

      const trx = await claimNftContract?.call("claimNFT",selectedNfts1, selectedNfts2);
      
      console.log(trx);

      setChangeDetection((value)=>!value)

      setEquipLoading(false);
    } catch (err: any) {
      setEquipLoading(false);
      console.log(err);
      throw new Error(err?.message);
    }
  };

  return (
    <div className="nft-renderer-container">
      <div className="nft-equip-button-container">
        {(ownedNfts1?.data?.length && ownedNfts2?.data?.length)?
         <button
         className="nft-equip-button-item"
         onClick={mintSneaker}
         disabled={selectedNfts1 === -1 || selectedNfts2 === -1 || equipLoading}
       >
           {equipLoading?'Loading...':'Equip'}
       </button>:<></>}
       
      </div>
      <div className="nft-renderer-section">
        <div className="nft-renderer-left-section">
          <h1>Blank NFT 1</h1>
          {ownedNfts1?.isLoading && 'Loading...'}
          {!ownedNfts1?.isLoading && (!ownedNfts1?.data?.length) && 'No NFT Owned'}
          {ownedNfts1?.data?.length ? (
            <div className="nft-renderer-items">
              {ownedNfts1?.data?.map((item, index: number) => {
                return (
                  <div
                    className="nft-renderer-items-data"
                    key={index}
                    onClick={() => {
                      selectNft1(parseInt(item?.metadata?.id));
                    }}
                  >
                    <div className="nft-renderer-item-data-checkbox">
                      <input
                        type="checkbox"
                        checked={selectedNfts1 === parseInt(item?.metadata?.id)}
                        onClick={() => {
                          selectNft1(parseInt(item?.metadata?.id));
                        }}
                      />
                    </div>
                    <h3 className="nft-renderer-item-data-id">
                      #{item?.metadata?.id}
                    </h3>
                    <ThirdwebNftMedia
                      metadata={item?.metadata}
                      className="nft-renderer-item-data-media"
                    />
                    <h2 className="nft-renderer-item-data-title">
                      {item?.metadata?.name}
                    </h2>
                  </div>
                );
              })}
            </div>
          ):<></>}
        </div>
        <div className="nft-renderer-left-section">
          <h1>Eggs NFT</h1>
          {ownedNfts2?.isLoading && 'Loading...'}
          {!ownedNfts2?.isLoading && (!ownedNfts2?.data?.length) && 'No NFT Owned'}
          {ownedNfts2?.data?.length ? (
            <div className="nft-renderer-items">
              {ownedNfts2?.data?.map((item, index: number) => {
                return (
                  <div
                    className="nft-renderer-items-data"
                    key={index}
                    onClick={() => {
                      selectNft2(parseInt(item?.metadata?.id));
                    }}
                  >
                    <div className="nft-renderer-item-data-checkbox">
                      <input
                        type="checkbox"
                        checked={selectedNfts2 === parseInt(item?.metadata?.id)}
                        onClick={() => {
                          selectNft2(parseInt(item?.metadata?.id));
                        }}
                      />
                    </div>
                    <h3 className="nft-renderer-item-data-id">
                      #{item?.metadata?.id} {`(${item?.metadata?.supply})`}
                    </h3>
                    <ThirdwebNftMedia
                      metadata={item?.metadata}
                      className="nft-renderer-item-data-media"
                    />
                    <h2 className="nft-renderer-item-data-title">
                      {item?.metadata?.name}
                    </h2>
                  </div>
                );
              })}
            </div>
          ):<></>}
        </div>
      </div>
    </div>
  );
};

export default NFTRendering;
